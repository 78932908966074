/**
 * IMPORTS
 */
import {Map} from 'immutable';
import {IContactTypes} from 'src/aggregates/customers/state.d';
import {ICustomerState} from 'src/aggregates/customers/state.d';


/**
 * CODE
 */
const contactTypesInitialState: IContactTypes = {
    byId: {},
    hasError: false,
    isFetching: false,
};

const initialState: ICustomerState = {
    byContact: Map(),
    byId: Map(),
    contactAlreadyExists: false,
    contactTypes: contactTypesInitialState,
    currentCustomer: null,
    hasEditionError: false,
    hasError: false,
    hasLoadingError: false,
    hasLoadingUnassociatedContactsError: false,
    hasMorePages: true,
    hasRemovalError: false,
    hasTaggingError: false,
    isCreating: false,
    isEditing: false,
    isLoading: false,
    isLoadingUnassociatedContacts: false,
    isRemoving: false,
    isTagging: false,
    page: 0,
    searchTerm: '',
    tagsFilter: [],
};


/**
 * EXPORTS
 */
export default initialState;
