/**
 * CONSTANTS AND DEFINITIONS
 */
const messages = {
    'CVJPZ0001E': '{{user}}/{{id}} lost connection. Trying to reconnect',
    'CVJPZ0002I': '{{timestamp}}: [foreground: {{foreground}}] Agent ' +
                  '{{company}}/{{agent}} sent heartbeat',
    'CVJPZ0003I': '{{timestamp}}: Agent {{company}}/{{agent}} heartbeat ' +
                  'successful ({{status}}) after {{elapsed}}ms',
    'CVJPZ0004E': '{{timestamp}}: Agent {{company}}/{{agent}} heartbeat ' +
                  'failed ({{status}}) after {{elapsed}}ms',
    'CVJPZ0005E': '{{timestamp}}: Agent {{company}}/{{agent}} heartbeat ' +
                  'conflict after {{elapsed}}ms',
    'CVJPZ0006E': '{{timestamp}}: Agent {{company}}/{{agent}} heartbeat not ' +
                  'sent: conversis unavailable',
    'CVJPZ0007I': '{{timestamp}}: Agent {{company}}/{{agent}} opening session',
    'CVJPZ0008I': '{{timestamp}}: Agent {{company}}/{{agent}} session opened ' +
                  'successfully ({{status}}) after {{elapsed}}ms',
    'CVJPZ0009E': '{{timestamp}}: Agent {{company}}/{{agent}} session open ' +
                  'failed ({{status}}) after {{elapsed}}ms',
    'CVJPZ0010E': '{{timestamp}}: Agent {{company}}/{{agent}} session open ' +
                  'aborted: conversis unavailable',
    'CVJPZ0011I': '{{timestamp}}: Agent {{company}}/{{agent}} ' +
                  'reauthenticating on server "{{server}}"',
    'CVJPZ0012I': '{{timestamp}}: Agent {{company}}/{{agent}} reconnecting ' +
                  'to XMPP',
    'CVJPZ0013E': '{{timestamp}}: Agent {{company}}/{{agent}} reconnection ' +
                  'to XMPP failed after {{elapsed}}ms',
    'CVJPZ0014I': '{{timestamp}}: Agent {{company}}/{{agent}} closing session',
    'CVJPZ0015E': '{{timestamp}}: Agent {{company}}/{{agent}} session close ' +
                  'failed: conversis unavailable',
    'CVJPZ0016E': '{{timestamp}}: Agent {{company}}/{{agent}} session close ' +
                  'failed after {{elapsed}}ms: {{status}}',
    'CVJPZ0017E': 'Failed to load conversation history for agent {{agent}}: ' +
                  '{{reason}}',
    'CVJPZ0020E': 'Failed to send template for conversation {{conversation}}',
    'CVJPZ0022E': 'Failed to update message: invalid id',
    'CVJPZ0023E': 'Failed to update message: incomplete request',
    'CVJPZ0024E': 'Failed to fetch customer of id {{id}}',
    'CVJPZ0025E': 'User "{{user}}" authentication failed on server {{server}}' +
                  ': {{reason}}',
    'CVJPZ0026E': 'User "{{user}}" authorization as {{role}} failed: ' +
                  '{{reason}}',
    'CVJPZ0027E': 'User "{{user}}" login failed: {{reason}}',
    'CVJPZ0028E': 'Error during service worker registration: {{reason}}',
    'CVJPZ0029E': 'Error during service worker unregistration: {{reason}}',
    'CVJPZ0030E': 'Error during service worker registration, the browser has ' +
                   'not service worker functionality',
    'CVJPZ0031E': 'Error during service worker unregistration, the browser ' +
                   'has not service worker functionality',
    'CVJPZ0032E': 'User "{{user}}" authorization failed, company ' +
                  '"{{company}}" not allowed to login on server {{server}}',
    'CVJPZ0033I': 'User "{{user}}" provided feedback with score {{score}} ' +
                  'and said: {{response}}',
    'CVJPZ0034E': 'Failed to save user {{user}} feedback: {{reason}}',
    'CVJPZ0035E': 'User {{user}} failed to fetch customer for ' +
                  '"{{channel}}/{{contact}}": {{reason}}',
    'CVJPZ0036E': 'User {{user}} failed to fetch customer page {{page}}: ' +
                  '{{reason}}',
    'CVJPZ0037E': 'User {{user}} failed to fetch customers by tags: {{reason}}',
    'CVJPZ0038E': 'User {{user}} failed to compress image: {{reason}}',
    'CVJPZ0040E': 'User {{user}} failed to send message: {{reason}}',
    'CVJPZ0041E': 'User {{user}} failed to parse referral field on message ' +
                  '{{id}}: {{reason}}',
    'CVJPZ0042E': 'User {{user}} failed to create conversation with account: ' +
                  '{{account}} and contact: {{contact}}. Reason: {{reason}}',
    'CVJPZ0043E': 'User "{{user}}" pre-authentication failed on server ' +
                  '{{server}}: {{reason}}',
    'CVJPZ0044E': 'User "{{user}}" pre-authentication failed for all ' +
                  'servers: {{reason}}',
    'CVJPZ0045E': '{{timestamp}}: Agent {{company}}/{{agent}} reconnection ' +
                  'to XMPP failed after {{elapsed}}ms: {{error}}',
    'CVJPZ0046I': 'User "{{user}}" connecting to XMPP as "{{jid}}" at ' +
                  '"{{boshURL}}"',
    'CVJPZ0047I': 'User {{user}} logging in as "{{role}}"',
    'CVJPZ0048E': 'User {{user}} failed to create conversation by external ' +
                  'request: {{reason}}',
    'CVJPZ0049E': 'User {{user}} failed to fetch account {{account}} ' +
                  'templates: {{reason}}',
    'CVJPZ0050E': 'User {{user}} failed to fetch conversations ' +
                  'metrics: {{reason}}',
    'CVJPZ0051E': 'User {{user}} failed to fetch answered customers ' +
                  'metrics: {{reason}}',
    'CVJPZ0052E': 'User "{{company}}/{{user}}" failed to add ' +
                  '"{{channel}}/{{contact}}" to a customer: duplicate found ' +
                  'at "{{customer}}/{{name}}"',
    'CVJPZ0053E': 'User {{company}}/{{user}} could not receive message send' +
                  'failure information: {{reason}}',
    'CVJPZ0054E': 'User {{company}}/{{user}} failed to subscribe for push ' +
                  'notifications: {{reason}}',
    'CVJPZ0060I': 'User "{{user}}/{{name}}" detected heartbeat failure and ' +
                  'will reopen session.',
    'CVJPZ0062E': 'User "{{user}}/{{name}}" failed to reconnect after ' +
                  'heartbeat failure. Scheduled a new tentative.',
    'CVJPZ0063E': 'User "{{user}}/{{name}}" failed to fetch company ' +
                  'configurations: {{reason}}',
    'CVJPZ0064E': 'User "{{user}}/{{name}}" failed to report paused status',
    'CVJPZ0065E': 'User "{{user}}/{{name}}" failed to fetch user config: ' +
                  '{{reason}}',
    'CVJPZ0066E': 'User "{{user}}/{{name}}" failed to update user config: ' +
                  '{{reason}}',
};


/**
 * EXPORTS
 */
export {
    messages,
};
