/**
 * TYPES
 */
import {IContact as IGraphisContact} from 'src/aggregates/customers/graphis.d';
import {ICustomer as ICustomerData} from 'src/aggregates/customers/graphis.d';
import {IContact} from 'src/aggregates/customers/state.d';
import {ICustomer} from 'src/aggregates/customers/state.d';
import {ICustomerAgent} from 'src/aggregates/customers/state.d';
import {ICustomerTag} from 'src/aggregates/customers/state.d';
import {_aggregates} from 'src/aggregates/index.d';
import {IEvent} from 'src/aggregates/index.d';


/**
 * EXPORTS
 */

/**
 * Events types.
 */
export enum types
{
    CUSTOMER_ADDED = 'CUSTOMER_ADDED',
    CUSTOMER_ALL_PAGES_LOADED = 'CUSTOMER_ALL_PAGES_LOADED',
    CUSTOMER_CONTACT_ALREADY_EXISTS = 'CUSTOMER_CONTACT_ALREADY_EXISTS',
    CUSTOMER_CONTACT_TYPE_ADDED = 'CUSTOMER_CONTACT_TYPE_ADDED',
    CUSTOMER_CONTACT_TYPES_FETCHED = 'CUSTOMER_CONTACT_TYPES_FETCHED',
    CUSTOMER_CONTACT_TYPES_FETCHING = 'CUSTOMER_CONTACT_TYPES_FETCHING',
    CUSTOMER_CONTACT_TYPES_NOT_FETCHED = 'CUSTOMER_CONTACT_TYPES_NOT_FETCHED',
    CUSTOMER_CREATED = 'CUSTOMER_CREATED',
    CUSTOMER_CREATING = 'CUSTOMER_CREATING',
    CUSTOMER_ERROR_RESET = 'CUSTOMER_ERROR_RESET',
    CUSTOMER_FETCHED = 'CUSTOMER_FETCHED',
    CUSTOMER_FOCUSED = 'CUSTOMER_FOCUSED',
    CUSTOMER_LOADED = 'CUSTOMER_LOADED',
    CUSTOMER_LOADING = 'CUSTOMER_LOADING',
    CUSTOMER_NOT_CREATED = 'CUSTOMER_NOT_CREATED',
    CUSTOMER_NOT_LOADED = 'CUSTOMER_NOT_LOADED',
    CUSTOMER_NOT_REMOVED = 'CUSTOMER_NOT_REMOVED',
    CUSTOMER_NOT_TAGGED = 'CUSTOMER_NOT_TAGGED',
    CUSTOMER_NOT_UNTAGGED = 'CUSTOMER_NOT_UNTAGGED',
    CUSTOMER_NOT_UPDATED = 'CUSTOMER_NOT_UPDATED',
    CUSTOMER_PAGE_LOADED = 'CUSTOMER_PAGE_LOADED',
    CUSTOMER_REMOVED = 'CUSTOMER_REMOVED',
    CUSTOMER_REMOVING = 'CUSTOMER_REMOVING',
    CUSTOMER_SEARCH_TERM_ADDED = 'CUSTOMER_SEARCH_TERM_ADDED',
    CUSTOMER_TAGGED = 'CUSTOMER_TAGGED',
    CUSTOMER_TAGGING = 'CUSTOMER_TAGGING',
    CUSTOMER_TAGS_FILTER_UPDATED = 'CUSTOMER_TAGS_FILTER_UPDATED',
    CUSTOMER_UNTAGGED = 'CUSTOMER_UNTAGGED',
    CUSTOMER_UPDATED = 'CUSTOMER_UPDATED',
    CUSTOMER_UPDATING = 'CUSTOMER_UPDATING',
}


/**
 * Events interfaces.
 */
export interface ICustomerAdded extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_ADDED;
    address1?: string;
    address2?: string;
    city?: string;
    code?: string;
    agents: ICustomerAgent[];
    company: number;
    contacts: IContact[];
    description?: string;
    document?: string;
    email?: string;
    name: string;
    organization?: string;
    state?: string;
    tags?: ICustomerTag[];
    type: types.CUSTOMER_ADDED;
    unassociated: boolean;
    zipcode?: string;
}

export interface ICustomerAllPagesLoaded extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_ALL_PAGES_LOADED;
    type: types.CUSTOMER_ALL_PAGES_LOADED;
}

export interface ICustomerContactAlreadyExists extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_CONTACT_ALREADY_EXISTS;
    type: types.CUSTOMER_CONTACT_ALREADY_EXISTS;
}

export interface ICustomerContactTypeAdded extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_CONTACT_TYPE_ADDED;
    channel: string;
    id: number;
    name: string;
    prefix: string;
    type: types.CUSTOMER_CONTACT_TYPE_ADDED;
}

export interface ICustomerContactTypesFetched extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_CONTACT_TYPES_FETCHED;
    type: types.CUSTOMER_CONTACT_TYPES_FETCHED;
}

export interface ICustomerContactTypesFetching extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_CONTACT_TYPES_FETCHING;
    type: types.CUSTOMER_CONTACT_TYPES_FETCHING;
}

export interface ICustomerContactTypesNotFetched extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_CONTACT_TYPES_NOT_FETCHED;
    type: types.CUSTOMER_CONTACT_TYPES_NOT_FETCHED;
}

export interface ICustomerCreated extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_CREATED;
    type: types.CUSTOMER_CREATED;
}

export interface ICustomerCreating extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_CREATING;
    type: types.CUSTOMER_CREATING;
}

export interface ICustomerErrorReset extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_ERROR_RESET;
    type: types.CUSTOMER_ERROR_RESET;
}

export interface ICustomerFetched extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_FETCHED;
    address1: string;
    address2: string;
    agents: IAgent[];
    city: string;
    code: string;
    company: number;
    contacts: IContact[];
    description: string;
    document: string;
    email: string;
    name: string;
    organization: string;
    state: string;
    tags: ICustomerTag[];
    type: types.CUSTOMER_FETCHED;
    zipcode: string;
}

export interface ICustomerFocused extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_FOCUSED;
    customer: string | number | null;
    type: types.CUSTOMER_FOCUSED;
}

export interface ICustomerLoaded extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_LOADED;
    customers: ICustomerData[];
    type: types.CUSTOMER_LOADED;
}

export interface ICustomerLoading extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_LOADING;
    type: types.CUSTOMER_LOADING;
}

export interface ICustomerNotCreated extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_NOT_CREATED;
    type: types.CUSTOMER_NOT_CREATED;
}

export interface ICustomerNotLoaded extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_NOT_LOADED;
    type: types.CUSTOMER_NOT_LOADED;
}

export interface ICustomerNotRemoved extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_NOT_REMOVED;
    type: types.CUSTOMER_NOT_REMOVED;
}

export interface ICustomerNotTagged extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_NOT_TAGGED;
    type: types.CUSTOMER_NOT_TAGGED;
}

export interface ICustomerNotUntagged extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_NOT_UNTAGGED;
    type: types.CUSTOMER_NOT_UNTAGGED;
}

export interface ICustomerNotUpdated extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_NOT_UPDATED;
    type: types.CUSTOMER_NOT_UPDATED;
}

export interface ICustomerPageLoaded extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_PAGE_LOADED;
    customers: ICustomer[];
    page: number;
    type: types.CUSTOMER_PAGE_LOADED;
}

export interface ICustomerRemoved extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_REMOVED;
    type: types.CUSTOMER_REMOVED;
}

export interface ICustomerRemoving extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_REMOVING;
    type: types.CUSTOMER_REMOVING;
}

export interface ICustomerSearchTermAdded extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_SEARCH_TERM_ADDED;
    term: string;
    type: types.CUSTOMER_SEARCH_TERM_ADDED;
}

export interface ICustomerTagged extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_TAGGED;
    customerTag: ICustomerTag;
    type: types.CUSTOMER_TAGGED;
}

export interface ICustomerTagging extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_TAGGING;
    type: types.CUSTOMER_TAGGING;
}

export interface ICustomerUntagged extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_UNTAGGED;
    customerTag: number;
    type: types.CUSTOMER_UNTAGGED;
}

export interface ICustomerUpdated extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_UPDATED;
    address1: string;
    address2: string;
    agents: IAgent[];
    city: string;
    code: string;
    company: number;
    contacts: IContact[];
    document: string;
    email: string;
    name: string;
    description: string;
    organization: string;
    state: string;
    zipcode: string;
    type: types.CUSTOMER_UPDATED;
}

export interface ICustomerUpdating extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_UPDATING;
    type: types.CUSTOMER_UPDATING;
}

export interface ICustomerTagsFilterUpdated extends IEvent
{
    _aggregate: _aggregates.CUSTOMERS;
    _name: types.CUSTOMER_TAGS_FILTER_UPDATED;
    tags: number[];
    type: types.CUSTOMER_TAGS_FILTER_UPDATED;
}

